<template lang="pug">
	.ov-table-wrapper.param-table
		table.table.table-bordered
			thead
				tr
					th(width="200px") Key
					th(width="200px") Format
					th Description
					th(width="300px") Available Values
			tbody
				tr(v-for="r in rows", v-bind:key="r.name")
					td {{ r.key }}
					td
						b-badge.format-badge(:variant="formatVariant(r.format)", @click="onClick(r.format)") {{ r.format }}
							span.ml-1(v-if="r.array") []
					td(style="white-space: break-spaces") {{ r.desc }}
					td
						span(v-if="r.values")
							b-badge.ml-1(v-for="v in r.values", variant="light") {{ v }}
</template>
<style lang="scss">
	.param-table {
		.badge {
			font-size: 10px;
		}
		.badge.format-badge {
			font-size: 12px;
			&:not(.badge-light) {
				cursor: pointer;
			}
		}
	}
</style>
<script>
	export default {
		name: 'param-table',
		props: {
			rows: {
				type: Array,
				required: true,
			}
		},
		data(){
			return {

			}
		},
		methods: {
			formatVariant(format) {
				switch (format) {
					case 'Offer':
						return 'primary';
					case 'Event':
						return 'info';
					case 'WhiteList/BlackList':
						return 'dark';
					case 'Creative':
						return 'warning';
					case 'Pacing':
						return 'danger';

					case 'Targeting':
						return 'success';
					case 'Country':
					case 'Region':
					case 'City':
						return 'secondary';

					default:
						return 'light';
				}
			},
			onClick(format) {
				this.$emit('format-clicked', format);
			},
		},

	}
</script>
